import React from 'react';
import {useTranslation} from "react-i18next";
import {ImgWrapper} from './home.styles';
import Layout from "../../layout";


const Home = () => {
    const {t} = useTranslation()
    return (
        <Layout>
            <section className={'pt-8 md:pt-12 lg:pt-20 pb-0 md:pb-0 '}>
                <div className={'container px-6 mx-auto text-center'}>
                    <h1 className={'text-3xl font-black sm:text-4xl md:text-5xl lg:text-6xl'}>
                        {t('home.title')}
                    </h1>
                    <div
                        className={'max-w-xl mx-auto mt-4 text-lg font-light leading-tight text-gray-500 sm:text-xl md:text-2xl'}>
                        <p>{t('home.description')}</p>
                    </div>
                </div>
                <div className={'container px-6 mx-auto'}>
                    <div
                        className={'w-full h-40 mt-6 overflow-hidden sm:rounded-t-xl md:mt-12 lg:mt-20 md:h-56 lg:h-80'}>
                            <span
                                className={'box-sizing:border-box;display:block;overflow:hidden;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;position:relative'}>
                                        <span
                                            className={'box-sizing:border-box;display:block;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;padding-top:50%'}>
                                            < ImgWrapper/>
                                </span>
                            </span>
                    </div>
                </div>
            </section>
            <section className={'py-8 md:py-12 lg:py-20 bg-gray-50'}>
                <div className={'container px-6 mx-auto text-center'}>
                    <h2 className={'text-3xl font-black sm:text-4xl md:text-5xl lg:text-6xl'}>{t('home.title_card')}</h2>
                    <div
                        className={'max-w-xl mx-auto mt-2 text-lg font-light leading-tight text-gray-500 sm:text-xl md:text-2xl'}>
                        <p>{t('home.title_card_description')}</p>
                    </div>
                </div>
                <div className={'container px-6 mx-auto'}>
                    <div className={'grid justify-center gap-20 pt-20 lg:grid-cols-3'}>
                        <div className={'max-w-sm text-center lg:max-w-none'}><h3
                            className={'text-2xl font-bold'}>{t('home.title_card_subtitle_one')}</h3>
                            <div className={'pt-2 text-lg'}><p>{t('home.title_card_subtitle_description_one')}</p></div>
                        </div>
                        <div className={'max-w-sm text-center lg:max-w-none'}><h3
                            className={'text-2xl font-bold'}>{t('home.title_card_subtitle_two')}</h3>
                            <div className={'pt-2 text-lg'}><p>{t('home.title_card_subtitle_description_two')}</p></div>
                        </div>
                        <div className={'max-w-sm text-center lg:max-w-none'}><h3
                            className={'text-2xl font-bold'}>{t('home.title_card_subtitle_three')}</h3>
                            <div className={'pt-2 text-lg'}><p>{t('home.title_card_subtitle_description_three')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Home;