import React from 'react';
import {Route, Routes} from "react-router-dom";
import {paths} from "./helper";
import Home from "../pages/home/home";
import About from "../pages/about/about";
import Contacts from "../pages/contacts/contacts";

export const PublicRoutes = () => {
    return (
        <Routes>
            <Route path={paths.home} element={<Home/>}/>
            <Route path={paths.about} element={<About/>}/>
            <Route path={paths.contacts} element={<Contacts/>}/>
        </Routes>

    );
}