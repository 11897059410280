import React from 'react';
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
interface LayoutProps {
    children: React.ReactNode
}

const Layout = ({children}: LayoutProps) => {
    return (
        <div>
            <Header />
            {children}
            <Footer/>
        </div>
    );
};

export default Layout;