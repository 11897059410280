import React from 'react';

const Footer = () => {
    let currentYear= new Date().getFullYear()
    return (
        <footer className={'border-t'}>
            <div className={'container px-6 py-12 mx-auto'}>
                <div className={'flex flex-col items-center justify-between text-sm md:flex-row'}>
                    © {currentYear} React
                </div>
            </div>

        </footer>
    );
};

export default Footer;