import {Link} from "react-router-dom";
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";


export default function LocaleSwitcher() {
  const {i18n} = useTranslation()

  const changeLanguage = (locale: any) => {
  i18n.changeLanguage(locale)
  }

  return (

    <ul className='flex gap-x-3'>
      {['en','uk'].map((locale) => {
        return (
          <li key={locale}>
            <button
               onClick={() => changeLanguage(locale)}
              className='rounded-md border bg-black px-3 py-2 text-white rounded-tl-lg rounded-bl-[1rem] rounded-br-[1rem] rounded-tr-[1rem] rounded-tl-[1rem]'
            >
              {locale}
            </button>
          </li>
        )
      })}
    </ul>
  )
}
