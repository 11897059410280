import {styled} from 'styled-components';
import {TabsList as BaseTabsList} from '@mui/base/TabsList';
import {TabPanel as BaseTabPanel} from '@mui/base/TabPanel';
import {buttonClasses} from '@mui/base/Button';
import {Tab as BaseTab, tabClasses} from '@mui/base/Tab';
import {ColorEnum} from "../enums/color.enum";
import {Typography} from "../typography/typography";

export const TypographyWrap = styled(Typography)`
  font-weight: 600;
  color: ${ColorEnum.White};
`




const Btn = styled.button`
 white-space: nowrap;
 color: hsla(150, 14%, 97%, 1);
 cursor: pointer;
 outline: none;
 font-size: 1rem;
 text-shadow: 0.1rem 0.1rem 0.5rem hsla(0, 0%, 0%, 0.5);
 letter-spacing: 0.1rem;
 border-radius: 0.5rem;
 user-select: none;
 padding: 0.4rem 1.2rem;
 margin: 0.2rem;
 transition: all 0.1s ease-in;

 ::-moz-focus-inner {
  border: 0;
 }



@media screen and (max-width: 45em) {
  padding: 1rem 1rem;
  font-size: 0.9rem;
  margin: 0.5rem;
 }
`

export const SignInBtn = styled(Btn)`
 text-decoration: none;
 background-color: hsla(189, 85%, 28%, 1);
 box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.0125),
  0 1px 1px rgba(0, 0, 0, 0.05);
 border-bottom-width: 0.6rem;

 &:hover {
  background-color: hsla(189, 85%, 32%, 1);
 }

 &:active {
  border-bottom-width: 0.1rem;
  border-top-width: 0.5rem;
 }
`



