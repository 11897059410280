import {styled} from "styled-components";

export const ImgWrapper = styled.img`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  background: url("https://cdn.pixabay.com/photo/2015/07/28/21/01/create-865017_1280.jpg")
`