import {styled} from "styled-components";
import {device} from "../../components/styles/adaptive/device";
import {Box} from "@mui/material";

export const DescriptionWrapper = styled(Box)`
  padding-top: 32px;
`
export const ImageWrapper = styled.img`
  @media ${device.mobileL} {
    width: 420px;
    height: 600px;
    object-fit: cover;
  }

  @media ${device.tablet} {
    width: 420px;
    height: 600px;
    object-fit: cover;
  }
  width: 220px;
  height: 300px;
  object-fit: cover;

`