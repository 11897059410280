import React from 'react';
import {useTranslation} from "react-i18next";
import Layout from "../../layout";
import {Box, Grid} from "@mui/material";
import {Typography} from "../../components/typography/typography";
import {DescriptionWrapper, ImageWrapper, TextWrapper} from "./about.styles";
import Cat from './../../components/images/about/cat.webp'

const About = () => {
    const {t} = useTranslation()
    return (
        <Layout>
            <section className={'pt-8 md:pt-12 lg:pt-20 pb-8 md:pb-12 lg:pb-20'}>
                <div className={'container px-6 mx-auto text-center'}>
                    <h1 className="text-3xl font-black sm:text-4xl md:text-5xl lg:text-6xl">
                        {t('about.title')}
                    </h1>
                    <div className={'max-w-xl mx-auto mt-2 text-lg font-light leading-tight text-gray-500 sm:text-xl md:text-2xl'}>
                        <p>{t('about.description')}</p>
                    </div>
                </div>

                <div className={'flex flex-col lg:flex-row items-center gap-8 p-8 max-w-8xl w-full'}>
                <div className={'flex flex-col space-y-4 lg:w-1/2 w-full pl-2 pr-2'}>

                    <TextWrapper>
                        <h5>
                            <Typography type='montserratSemiBold18'>
                                <Box style={{fontSize: '22px'}}>
                                    {t('about.subtitle')}
                                </Box>
                            </Typography>
                        </h5>
                        <DescriptionWrapper>
                            <span>{t('about.promo')}</span>
                        </DescriptionWrapper>
                    </TextWrapper>
                </div>


                 <div className={'lg:w-1/2 w-full mt-8 lg:mt-0'}>
                     <img src={Cat} alt="" className={'rounded-lg object-cover w-full h-full'}/>

                 </div>


                </div>

            </section>
        </Layout>
    );
};

export default About;