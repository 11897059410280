import React from 'react';
import {ColorEnum} from "../enums/color.enum";
import {Link} from "react-router-dom";
import {paths} from "../../helpers/helper";
import {Typography} from "../typography/typography";
import {Grid} from "@mui/material";
import {SignInBtn, TypographyWrap} from './header.styles';
import LocaleSwitcher from "../lang/locale-switcher";
import {useTranslation} from "react-i18next";

const Header = () => {
    const {t} = useTranslation()
    return (
        <>
            <header style={{padding: '16px 0', color: 'black', backgroundColor: `${ColorEnum.PrimaryColor}`}}>
                <Grid container justifyContent={'space-between'} alignItems={'center'} px={2}>
                    <Grid item >
                        <Grid container>
                            <Grid item>
                                <Link to={paths.home}>
                                    <TypographyWrap type={'montserratSemiBold18'}>  <SignInBtn >{t('navigation.home')}</SignInBtn></TypographyWrap>
                                </Link>
                            </Grid>
                            <Grid item px={0.2}>
                                <Link to={paths.about}>
                                    <Typography type={'montserratSemiBold18'}> <SignInBtn >{t('navigation.about')}</SignInBtn></Typography>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to={paths.contacts}>
                                    <Typography type={'montserratSemiBold18'}>  <SignInBtn >{t('navigation.contacts')}</SignInBtn></Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item ><LocaleSwitcher /></Grid>
                </Grid>

            </header>
        </>
    );
};

export default Header;