import React from 'react';
import './App.css';
import {useTranslation} from "react-i18next";
import {PublicRoutes} from "./helpers/public-routes";

function App() {
    const {t,i18n} = useTranslation()

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang)
    }
  return (
    <div >
      {/*<header className="App-header">*/}

      {/*</header>*/}

        <PublicRoutes />

    </div>
  );
}

export default App;
