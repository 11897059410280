import React from 'react';
import Header from "../../components/header/header";
import Layout from "../../layout";
import {Box, Grid} from "@mui/material";
import {DescriptionWrapper, ImageWrapper} from "./contacts.styles";
import Phone from "../../components/images/contacts/phone.png";
import {Typography} from "../../components/typography/typography";
import {useTranslation} from "react-i18next";

const Contacts = () => {
    const {t} = useTranslation()
    return (
        <Layout>
            <section className={'pt-8 md:pt-12 lg:pt-20 pb-8 md:pb-12 lg:pb-20'}>
                <div className={'container px-6 mx-auto text-center'}>
                    <h1 className="text-3xl font-black sm:text-4xl md:text-5xl lg:text-6xl">
                        {t('contacts.title')}
                    </h1>
                    <div
                        className={'max-w-xl mx-auto mt-2 text-lg font-light leading-tight text-gray-500 sm:text-xl md:text-2xl'}>
                        <p>{t('contacts.subtitle')}</p>
                    </div>
                </div>

                <div className={'flex flex-col lg:flex-row items-center gap-8 p-8 max-w-8xl w-full'}>
                    <div className={'flex flex-col space-y-4 lg:w-1/2 w-full pl-2 pr-2'}>
                        <h5>
                            <Typography type='montserratSemiBold18'>
                                <Box style={{fontSize: '22px'}}>
                                    {t('contacts.promo')}
                                </Box>
                            </Typography>
                        </h5>
                        <DescriptionWrapper pt={8}>
                            <div>
                                <span>{t('contacts.description.first')}</span>
                            </div>
                            <div><span>{t('contacts.description.second')}</span></div>
                            <div><span>{t('contacts.description.third')}</span></div>
                            <div><span>{t('contacts.description.fourth')}</span></div>
                        </DescriptionWrapper>
                    </div>
                    <div className={'lg:w-1/2 w-full mt-8 lg:mt-0'}>
                        <img src={Phone} alt="phone" className={'rounded-lg object-cover w-full h-full'}/>
                    </div>
                    </div>
            </section>
        </Layout>
    );
};

export default Contacts;